/// <reference types="vite-plugin-svgr/client" />
import * as React from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { TextFieldElement, TextFieldElementProps } from 'react-hook-form-mui'
import SvgExpandDownIcon from '@assets/icons/expandDownIcon.svg?react'
import { commonColors } from '@styles/theme/colors'
import { useTheme } from '@hooks/useTheme'
import { CommonFormFieldProps } from '../types'

export type FormTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = TextFieldElementProps<TFieldValues, TName> & CommonFormFieldProps<TFieldValues>

export const FormTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  size = 'small',
  variant = 'outlined',
  fullWidth = true,
  className,
  ...props
}: FormTextFieldProps<TFieldValues, TName>): React.ReactElement => {
  const { currentTheme } = useTheme()

  return (
    <TextFieldElement
      control={control}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      className={className}
      SelectProps={{
        IconComponent: SvgExpandDownIcon,
        MenuProps: {
          PaperProps: {
            sx: {
              backgroundColor: currentTheme.palette.mode === 'light' ? commonColors.white : commonColors.black,
              '.MuiMenuItem-root': {
                fontSize: { xs: '16px', md: '16px', lg: '18px' },
                fontWeight: 500,
                minHeight: 'auto'
              }
            }
          }
        }
      }}
      {...props}
    />
  )
}
